<template>
  <div>
    <v-data-table
      dense
      :headers="indentWiseHeaders"
      :items="allIndents"
      :search="searchIndent"
      fixed-header
      class="elevation-2"
    >
      <template v-slot:top>
        <v-toolbar dense flat>
          <v-flex xs12 sm12 md4>
            <v-text-field
              dense
              rounded
              filled
              v-model="searchIndent"
              placeholder="Search  "
              append-icon="search"
              label="Search"
              background-color="background"
              single-line
              class="ma-0 pa-0 form-label"
              hide-details
            ></v-text-field>
          </v-flex>
          <v-spacer />
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-lock-reset</v-icon>
              </v-btn>
            </template>
            <span>Refresh</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>print</v-icon>
              </v-btn>
            </template>
            <span>Print</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-file-excel</v-icon>
              </v-btn>
            </template>
            <span>Excel</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-file-pdf-box</v-icon>
              </v-btn>
            </template>
            <span>PDF</span>
          </v-tooltip> -->
        </v-toolbar>
        <v-divider />
        <v-card-text class="hidden-md-and-up">
          <v-menu
            ref="menu6"
            v-model="menu6"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                dense
                hide-details="auto"
                class="ma-0 pa-0 form-label"
                v-model="dateRangeIndent"
                :rules="[(v) => !!v || 'Date is required']"
                prepend-icon="mdi-calendar"
                v-on="on"
                readonly
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="editedItem.dateFilterForIndent"
              range
              no-title
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu6 = false">Cancel</v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  [
                    $refs.menu6.save(editedItem.dateFilterForIndent),
                    searchIndentByDate(),
                    (menu6 = false),
                  ]
                "
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
          <v-text-field
            dense
            rounded
            filled
            v-model="searchIndent"
            placeholder="Search  "
            append-icon="search"
            label="Search"
            background-color="background"
            single-line
            class="ma-0 pa-0 form-label"
            hide-details
          ></v-text-field>
        </v-card-text>
        <v-divider />
        <!-- <v-expand-transition>
                <v-card
                  flat
                  v-show="searchFilterFormForIndent"
                  class="mx-auto"
                  color="grey lighten-5"
                >
                  <v-card-subtitle>Search Filters</v-card-subtitle>
                  <v-card-text>
                    <v-form ref="searchForm">
                      <v-row>
                        <v-col cols="12" md="3">
                          <v-autocomplete
                            dense
                            hide-details="auto"
                            class="ma-0 pa-0 form-label"
                            v-model="searchObjIndent.project_code"
                            :items="allIndentSearchDetails"
                            item-text="project_name"
                            item-value="project_code"
                            label="Project"
                            clearable
                            :menu-props="{
                              bottom: true,
                              overflowY: true,
                            }"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-autocomplete
                            dense
                            hide-details="auto"
                            class="ma-0 pa-0 form-label"
                            v-model="searchObjIndent.indent_id"
                            :items="allIndentSearchDetails"
                            item-text="indent_id"
                            item-value="indent_id"
                            label="Indent Id "
                            clearable
                            :menu-props="{
                              bottom: true,
                              overflowY: true,
                            }"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-expand-transition> -->
        <v-divider />
      </template>
      <template v-slot:no-data>
        <v-card flat>
          <v-card-text>
            <div>
              <v-alert dense type="info">No Results Found !</v-alert>
            </div>
          </v-card-text>
        </v-card>
      </template>
      <template v-slot:[`item.view`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon right color="info" v-on="on" @click="openDialog(item)"
              >drive_file_rename_outline</v-icon
            >
          </template>
          <span>Open Indent</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// import Loading from '../components/loading';
export default {
  name: "Home",
  // components: { Loading },
  data: () => ({
    device_id: "",
    skeleton: true,
    menu4: false,
    menu5: false,
    menu3: false,
    menu6: false,
    menu7: false,
    menu8: false,
    searchIndent: "",
    searchFilterForm: false,
    searchFilterFormForIndentData: false,
    searchFilterFormForIndent: false,
    allIndentSearchDetails: [],
    searchObjIndent: {
      project_code: "",
      indent_id: "",
    },
    indentWiseHeaders: [
      {
        text: "Sno",
        value: "sno",
        sortable: false,
        class: "primary--text",
        divider: true,
        cellClass: "caption",
      },
      {
        text: "Map",
        value: "view",
        align: "center",
        sortable: false,
        class: "primary--text",
        divider: true,
        cellClass: "caption",
      },
      {
        text: "Date/Time",
        value: "date_time",
        class: "primary--text",
        divider: true,
        cellClass: "caption",
      },
      {
        text: "Latitude",
        value: "indent_id",
        class: "primary--text",
        divider: true,
        cellClass: "caption",
      },

      {
        text: "Longitude",
        value: "indent_date",
        class: "primary--text",
        divider: true,
        cellClass: "caption",
      },
      {
        text: "Network Status",
        value: "network_status",
        class: "primary--text",
        divider: true,
        cellClass: "caption",
      },
      {
        text: "Location",
        value: "location",
        class: "primary--text",
        divider: true,
        cellClass: "caption",
      },
    ],
    allIndents: [],
    editedItem: {
      dateFilter: [
        new Date().toISOString().substr(0, 10),
        new Date().toISOString().substr(0, 10),
      ],
      dateFilterForMaterial: [
        new Date().toISOString().substr(0, 10),
        new Date().toISOString().substr(0, 10),
      ],
      dateFilterForIndent: [
        new Date().toISOString().substr(0, 10),
        new Date().toISOString().substr(0, 10),
      ],
    },
  }),
  computed: {
    dateRangeText() {
      if (this.editedItem.dateFilter[0] > this.editedItem.dateFilter[1]) {
        return (
          this.editedItem.dateFilter[1] + "~" + this.editedItem.dateFilter[0]
        );
      } else {
        return (
          this.editedItem.dateFilter[0] + "~" + this.editedItem.dateFilter[1]
        );
      }
    },
    dateRangeIndent() {
      if (
        this.editedItem.dateFilterForIndent[0] >
        this.editedItem.dateFilterForIndent[1]
      ) {
        return (
          this.editedItem.dateFilterForIndent[1] +
          "~" +
          this.editedItem.dateFilterForIndent[0]
        );
      } else {
        return (
          this.editedItem.dateFilterForIndent[0] +
          "~" +
          this.editedItem.dateFilterForIndent[1]
        );
      }
    },
    dateRangeTextMaterial() {
      if (
        this.editedItem.dateFilterForMaterial[0] >
        this.editedItem.dateFilterForMaterial[1]
      ) {
        return (
          this.editedItem.dateFilterForMaterial[1] +
          "~" +
          this.editedItem.dateFilterForMaterial[0]
        );
      } else {
        return (
          this.editedItem.dateFilterForMaterial[0] +
          "~" +
          this.editedItem.dateFilterForMaterial[1]
        );
      }
    },
  },
};
</script>
